import { BaseTypes, DynamicObject } from "lance-gg";

export class TileObject extends DynamicObject {

  static get netScheme() {
    return Object.assign(
      {
        bits: { type: BaseTypes.TYPES.INT32 },
      },
      super.netScheme
    );
  }

  constructor(gameEngine, options, props) {
    props = props || { isStatic: 1 };
    super(gameEngine, options, props);
    this.bits = props.bits || 0;
  }

  syncTo(other) {
    super.syncTo(other);
    this.bits = other.bits;
  }

  toString() {
    return `TileObject[roomName=${this._roomName} id=${this.id} bits=${this.bits}]`;
  }


  // Level geometry
  static get NONE() {
    return 0;
  }
  static get PIT() {
    return 1 << 1;
  }
  static get ROCK_HARD() {
    return 1 << 2;
  }
  static get ROCK_SOFT() {
    return 1 << 3;
  }
  static get MAGMA() {
    return 1 << 4;
  }
  static get MAGMA_COOLED() {
    return 1 << 5;
  }
  static get MYTHRIL_ORE_BLUE() {
    return 1 << 6;
  }
  static get MYTHRIL_ORE_SILVER() {
    return 1 << 7;
  }
  static get MYTHRIL_INGOT_BLUE() {
    return 1 << 8;
  }
  static get MYTHRIL_INGOT_SILVER() {
    return 1 << 9;
  }
  static get BOULDER() {
    return 1 << 10;
  }
  static get DEPOSIT_POINT() {
    return 1 << 11;
  }

  // Pressure plates and doors
  static get PRESSURE_PLATE_A() {
    return 1 << 12;
  }
  static get PRESSURE_PLATE_B() {
    return 1 << 13;
  }

  // Doors
  static get DOOR_A() {
    return 1 << 14;
  }
  static get DOOR_B() {
    return 1 << 15;
  }
  static get DOOR_DOWN_A() {
    return 1 << 16;
  }
  static get DOOR_DOWN_B() {
    return 1 << 17;
  }
  static get SIDE_DOOR_A() {
    return 1 << 18;
  }
  static get SIDE_DOOR_B() {
    return 1 << 19;
  }
  static get SIDE_DOOR_DOWN_A() {
    return 1 << 20;
  }
  static get SIDE_DOOR_DOWN_B() {
    return 1 << 21;
  }
  static get WALL_SIDE_DOOR_A() {
    return 1 << 22;
  }
  static get WALL_SIDE_DOOR_B() {
    return 1 << 23;
  }

  // Players
  static get PLAYER() {
    return 1 << 24;
  }

  // Tools
  static get SHOVEL() {
    return 1 << 25;
  }
  static get PICKAXE() {
    return 1 << 26;
  }
  static get GLOVES() {
    return 1 << 27;
  }
  static get TORCH() {
    return 1 << 28;
  }

  // Boundary tiles
  //
  // Since bitwise operations are limited to 32 bit integers and
  // we have > 32 tiles in the tileset, boundary tiles are
  // handled differently. They are static and cannot be added or
  // removed anyway, so are ignored for the bitwise operations add,
  // remove and hasAny.
  static get CORNER_TOP_LEFT() {
    return (1 << 1) + 1;
  }
  static get CORNER_TOP_RIGHT() {
    return (1 << 2) + 1;
  }
  static get CORNER_BOTTOM_LEFT() {
    return (1 << 3) + 1;
  }
  static get CORNER_BOTTOM_RIGHT() {
    return (1 << 4) + 1;
  }
  static get WALL_TOP_SINGLE() {
    return (1 << 5) + 1;
  }
  static get HORIZONTAL_WALL() {
    return (1 << 6) + 1;
  }
  static get VERTICAL_WALL() {
    return (1 << 7) + 1;
  }
  static get VERTICAL_WALL_LEFT() {
    return (1 << 8) + 1;
  }
  static get VERTICAL_WALL_RIGHT() {
    return (1 << 9) + 1;
  }
  static get WALL_FRONT_SINGLE() {
    return (1 << 10) + 1;
  }
  static get WALL_FRONT_LEFT() {
    return (1 << 11) + 1;
  }
  static get WALL_FRONT() {
    return (1 << 12) + 1;
  }
  static get WALL_FRONT_RIGHT() {
    return (1 << 13) + 1;
  }
  static get WALL_TOP() {
    return (1 << 14) + 1;
  }

  // Bit manipulation
  set(bits) {
    this.bits = bits;
  }

  add(tileTypes) {
    if (!this.isBoundary()) {
      this.bits = this.bits | tileTypes;
    }
  }

  remove(tileTypes) {
    if (!this.isBoundary()) {
      this.bits = this.bits & ~tileTypes;
    }
  }

  clear() {
    this.bits = 0;
  }

  // Comparison
  is(tileType) {
    return this.bits == tileType;
  }

  hasAny(tileTypes) {
    return !this.isBoundary() && (this.bits & tileTypes) !== 0;
  }

  isBoundary() {
    return this.bits & 1;
  }

  // Properties
  isWalkable() {
    return !this.isBoundary() && !this.hasAny(TileObject.PLAYER) && (
      this.is(TileObject.NONE)
      || this.is(TileObject.MAGMA_COOLED)
      || this.is(TileObject.PRESSURE_PLATE_A) || this.is(TileObject.PRESSURE_PLATE_B)
      || this.is(TileObject.MYTHRIL_INGOT_BLUE) || this.is(TileObject.MYTHRIL_INGOT_SILVER)
      || this.is(TileObject.DOOR_DOWN_A) || this.is(TileObject.DOOR_DOWN_B)
      || this.is(TileObject.SIDE_DOOR_DOWN_A) || this.is(TileObject.SIDE_DOOR_DOWN_B)
    );
  }
}

export const TileCharacters = {
  "H": (TileObject.ROCK_HARD),
  "S": (TileObject.ROCK_SOFT),
  "M": (TileObject.MAGMA),
  "C": (TileObject.MAGMA_COOLED),
  "B": (TileObject.BOULDER),
  "O": (TileObject.PIT),
  "@": (TileObject.DEPOSIT_POINT),

  // Mythril
  "!": (TileObject.MYTHRIL_ORE_BLUE),
  "?": (TileObject.MYTHRIL_ORE_SILVER),

  "£": (TileObject.MYTHRIL_INGOT_BLUE),
  "$": (TileObject.MYTHRIL_INGOT_SILVER),

  // Doors and pressure plates
  "P": (TileObject.PRESSURE_PLATE_A),
  "p": (TileObject.PRESSURE_PLATE_B),

  "D": (TileObject.DOOR_A),
  "Q": (TileObject.DOOR_DOWN_A),
  "W": (TileObject.SIDE_DOOR_A),
  "E": (TileObject.SIDE_DOOR_DOWN_A),
  "R": (TileObject.WALL_SIDE_DOOR_A),

  "d": (TileObject.DOOR_B),
  "q": (TileObject.DOOR_DOWN_B),
  "w": (TileObject.SIDE_DOOR_B),
  "e": (TileObject.SIDE_DOOR_DOWN_B),
  "r": (TileObject.WALL_SIDE_DOOR_B),

  // Tools
  "T": (TileObject.TORCH),

  // Wall tiles
  "╔": (TileObject.CORNER_TOP_LEFT),
  "╗": (TileObject.CORNER_TOP_RIGHT),
  "╚": (TileObject.CORNER_BOTTOM_LEFT),
  "╝": (TileObject.CORNER_BOTTOM_RIGHT),
  "n": (TileObject.WALL_TOP_SINGLE),
  "u": (TileObject.WALL_FRONT_SINGLE),
  "-": (TileObject.HORIZONTAL_WALL),
  "|": (TileObject.VERTICAL_WALL),
  "<": (TileObject.VERTICAL_WALL_LEFT),
  ">": (TileObject.VERTICAL_WALL_RIGHT),
  "{": (TileObject.WALL_FRONT_LEFT),
  "}": (TileObject.WALL_FRONT_RIGHT),
  "=": (TileObject.WALL_FRONT),
  "#": [TileObject.WALL_TOP],
};

export { TileObject as default };
