import { BaseTypes, DynamicObject, Renderer } from "lance-gg";

export default class TeamObject extends DynamicObject {

  static get netScheme() {
    return Object.assign(
      {
        teamName: { type: BaseTypes.TYPES.STRING },
        playerIds: {
          type: BaseTypes.TYPES.LIST,
          itemType: BaseTypes.TYPES.INT16,
        },
        playerNames: {
          type: BaseTypes.TYPES.LIST,
          itemType: BaseTypes.TYPES.STRING,
        }
      },
      super.netScheme
    );
  }

  constructor(gameEngine, options, props) {
    props = props || {};
    props.isStatic = 1;
    super(gameEngine, options, props);

    this.teamName = props.teamName ?? "";
    this.playerIds = [];
    this.playerNames = [];
    this.game = null; // server only
  }

  toString() {
    return `TeamObject[roomName=${this._roomName} id=${this.id} name=${this.teamName} playerIds=[${this.playerIds.join()}]]`;
  }

  // Handle Object being removed from the game world
  onAddToWorld(gameEngine) {
    if (Renderer) {
      let renderer = Renderer.getInstance();
      // Handle adding this object to the renderer on the client
      renderer.teamAdded();
    }
  }

  // Handle object being added to the game world
  onRemoveFromWorld(gameEngine) {
    if (Renderer) {
      let renderer = Renderer.getInstance();
      // Handle adding this object to the renderer on the client
      renderer.teamRemoved();
    }
  }

  // Handle synchronization of object state
  syncTo(other) {
    super.syncTo(other);
    this.teamName = other.teamName;
    this.playerIds = other.playerIds;
    this.playerNames = other.playerNames;
  }

  destroy() { }
}
