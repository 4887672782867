import { BaseTypes, DynamicObject, Renderer } from "lance-gg";

export class PlayerObject extends DynamicObject {

  static get BUTTON_NONE() {
    return 0;
  }
  static get BUTTON_RESET() {
    return 1;
  }

  // Movement
  static get BUTTON_UP() {
    return 2;
  }
  static get BUTTON_DOWN() {
    return 3;
  }
  static get BUTTON_LEFT() {
    return 4;
  }
  static get BUTTON_RIGHT() {
    return 5;
  }

  // Interaction
  static get BUTTON_INTERACT() {
    return 6;
  }
  static get BUTTON_DEPOSIT() {
    return 7;
  }

  // Roles
  static get ROLE_GEOLOGIST() {
    return 0;
  }
  static get ROLE_MINER() {
    return 1;
  }
  static get ROLE_ENGINEER() {
    return 2;
  }
  static get ROLE_ALL() {
    return 3;
  }

  // Orientation
  static get DIRECTION_LEFT() {
    return 0;
  }
  static get DIRECTION_RIGHT() {
    return 1;
  }
  static get DIRECTION_DOWN() {
    return 2;
  }
  static get DIRECTION_UP() {
    return 3;
  }

  static get netScheme() {
    return Object.assign(
      {
        name: { type: BaseTypes.TYPES.STRING },
        role: { type: BaseTypes.TYPES.INT8 },
        row: { type: BaseTypes.TYPES.INT8 },
        column: { type: BaseTypes.TYPES.INT8 },
        directionFaced: { type: BaseTypes.TYPES.INT8 },
        interactionPrompt: { type: BaseTypes.TYPES.STRING },
        interactionEnabled: { type: BaseTypes.TYPES.INT8 },
        mythrilHeld: { type: BaseTypes.TYPES.INT8 },
        hasBoulder: { type: BaseTypes.TYPES.INT8 },
      },
      super.netScheme
    );
  }

  constructor(gameEngine, options, props) {
    props = props ?? {};
    props.isStatic = 1;
    super(gameEngine, options, props);

    this.name = props.name ?? "";
    this.role = props.role ?? PlayerObject.ROLE_ALL;

    this.row = props.row ?? 0;
    this.column = props.column ?? 0;
    this.directionFaced = props.directionFaced ?? PlayerObject.DIRECTION_DOWN;
    this.tileFaced = null;

    this.interactionPrompt = this.role !== PlayerObject.ROLE_ALL
      ? InteractionPrompts[this.role]
      : InteractionPrompts.join("/");
    this.interactionEnabled = false;
    this.mythrilHeld = 0;
    this.hasBoulder = false;
  }

  // Handle synchronization of object state
  syncTo(other) {
    super.syncTo(other);
    this.name = other.name;
    this.role = other.role;
    this.row = other.row;
    this.column = other.column;
    this.directionFaced = other.directionFaced;
    this.interactionPrompt = other.interactionPrompt;
    this.interactionEnabled = other.interactionEnabled;
    this.mythrilHeld = other.mythrilHeld;
    this.hasBoulder = other.hasBoulder;
  }

  toString() {
    return `PlayerObject[roomName=${this._roomName} id=${this.id} playerId=${this.playerId} position=${[this.row, this.column]} role=${this.role} name=${this.name}]`;
  }

  reset(position) {
    this.row = position.row ?? 0;
    this.column = position.column ?? 0;

    this.directionFaced = PlayerObject.DIRECTION_DOWN;
    this.interactionEnabled = false;
    this.mythrilHeld = 0;
    this.hasBoulder = false;
  }

  // Handle object being added to the game world
  onAddToWorld(gameEngine) {
    if (Renderer) {
      let renderer = Renderer.getInstance();
      // Handle adding this object to the renderer on the client
      if (gameEngine.isOwnedByPlayer(this)) {
        renderer.playerAdded(this.playerId);
      } else {
        renderer.playerAdded();
      }
    }
  }

  // Handle Object being removed from the game world
  onRemoveFromWorld(gameEngine) {
    if (Renderer) {
      let renderer = Renderer.getInstance();
      // Handle adding this object to the renderer on the client
      if (gameEngine.isOwnedByPlayer(this)) {
        renderer.playerRemoved(this.playerId);
      } else {
        renderer.playerRemoved();
      }
    }
  }
}

export const InteractionPrompts = [
  "Dig/Mine Silver Mythril", "Dig/Mine Blue Mythril", "Pick Up/Place Boulder"
];

export { PlayerObject as default };
