import querystring from "query-string";
import { Lib } from "lance-gg";
import { PuzzClientEngine } from "./PuzzClientEngine";
import PuzzGameEngine from "../common/PuzzGameEngine";
import "./style/main.sass";
const qsOptions = querystring.parse(location.search);
import { h, render } from "preact"
import { useState, useEffect } from "preact/hooks"
import htm from "htm"
import { lazy, LocationProvider, ErrorBoundary, Router, Route, useLocation, useRoute } from 'preact-iso';
import { sha256 } from "js-sha256";
import Cookies from 'js-cookie'

window.addEventListener('beforeunload', function (e) {
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
});

// super insecure hashed local password
// only allows proctors to setup a room to join without logging in with admin credentials
const joinPassword = '084b25fe474fc44d1241553bf80577dbab79268f39d53129fb490727d63506f3'

// sent to both game engine and client engine
const defaults = {
  traceLevel: Lib.Trace.TRACE_ALL,
  serverURL: "/",
  delayInputCount: 8,
  scheduler: "fixed",
  syncOptions: {
    sync: "frameSync",
    localObjBending: 0,
    remoteObjBending: 0,
  },
  verbose: true,
};
let options = Object.assign(defaults, qsOptions);

// create a lobby that starts a client engine and a game engine
document.addEventListener("DOMContentLoaded", () => {
  const html = htm.bind(h)

  function Game() {
    return html`
      <div class="menu">
        <div class="container">
          <span class="title">Myth-Real Mining</span>
        </div>
      </div>
      <div class="container scoreboard" id="scoreScreen" style="display: none">
        <br />
        <br />
        <br />
        <span id="scoreText"></span>
      </div>
      <div class="app-content container" id="gameScreen">
        <div class="info">
          <div class="info-item team">
            <span id="teamInfo"></span>
          </div>
        </div>
        <button id="button-legend" class="button-legend">Help Page</button>
        <div class="board">
          <div class="board-content" id="board"></div>
        </div>
        <div class="mythrils">
          <button id="mythril-count" class="mythril-count">
            <span class="text"></span>
          </button>
        </div>
        <div class="inputs" id=playerButtons>
          <button id="buttonInteract">
            <span class="key">F</span>
            <span class="text">Interact</span>
          </button>
          <button id="buttonDeposit">
            <span class="key">G</span>
            <span class="text">Deposit Mythril</span>
          </button>
        </div>
      </div>
      <div class="legendPopup" id="legendPopup"></div>

      `
  }

  function Lobby({ teams }) {
    if (!teams) {
      return null
    }

    return (
      <div>
        {
          teams.map((t, i) => (
            <a href={'/room/'+t.name}>
            <button >
              {t.name}
            </button>
            </a>
          ))
        }
      </div>
    )
  }

  function Admin() {
    const [authenticated, setAuthenticated] = useState(undefined)

    useEffect(() => {
      (async () => {
        if (Cookies.get("admin-auth")){
          setAuthenticated(true)
          return
        }
        const res = await fetch("/admin")
        setAuthenticated(res.statusText === "OK");
      })()
    }, [])


    if (!authenticated) {
      return null
    }

    const [teams, setTeams] = useState(undefined)

    useEffect(() => {
      (async () => {
        const res = await fetch("/api/lobbies")
        const body = await res.json()
        setTeams(body)
      })()
    }, [])

    if (!teams) {
      return null
    }

    const resetLevel = (team, isAuthenticated) => 
    {
      if (!confirm(`reset current level for '${team}'?`)){
          return
      }
      fetch('/api/reset-level/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          teamId: team,
        })
      })
    }

    const resetToBeginning = (team, isAuthenticated) => 
    {
      if (!confirm(`reset everything for '${team}'?`)){
          return
      }
      fetch('/api/reset-to-beginning/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          teamId: team,
        })
      })
    }
    
    return (
      <div>
        <div class="admin-container">
          <h1 class="admin-title">Admin Screen</h1>
        </div>
        {
          teams.map((t, i) => (
            <div class="admin-team-container">
              <button onClick={() => resetLevel(t.name, authenticated)}> 
                Reset Current Level for Team: {t.name}
              </button>
              <button onClick={() => resetToBeginning(t.name, authenticated)}>
                Reset To Beginning for Team: {t.name} 
              </button>
            </div>
          ))
        }
      </div>
    )
  }

  function Room({chooseGame}) {
    const [enteredCorrectPassword, setEnteredCorrectPassword] = useState(false)
    const [pw, setPw] = useState("")
    const route = useRoute()
    const id = route.params['name']

    const handlePasswordInput = () => {
            const hashed = sha256.create().update(pw).hex()

            const correct = hashed === joinPassword
            if (!correct) {
              setPw('')
            }

            setEnteredCorrectPassword(correct)
    }

    if (!enteredCorrectPassword) {
      return (
        <div>
          <input onChange={e => {setPw(e.target.value)} } value={pw} style={{width: '300px', height: '50px', fontSize: '30px'}} type="password" placeholder="enter password"
          onKeyDown = {e => {
            if (e.key === 'Enter') {
              handlePasswordInput()
            }
          }}/>
          <button onClick={handlePasswordInput()}>submit</button>
        </div>
      )
    }

    return (
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        width: '25%',
      }}>
        <h1 style={{
          fontSize: '80px',
          textAlign: 'center',
        }}>{id}</h1>
        <button style={{
          textAlign: 'center',
          fontSize: '40px',
          margin: 'auto',
        } 
        } onClick={() => chooseGame(id)}>
          Join
        </button>
      </div>
    )
  }

  function App() {
    const [teams, setTeams] = useState(undefined)

    useEffect(() => {
      (async () => {
        const res = await fetch("/api/lobbies")
        const body = await res.json()
        setTeams(body)
      })()
    }, [])

    const [gameChoice, setGameChoice] = useState(undefined)
    useEffect(() => {
      if (gameChoice) {
        options.teamName = gameChoice
        const gameEngine = new PuzzGameEngine(options);
        const clientEngine = new PuzzClientEngine(gameEngine, options);
        clientEngine.start();
      }

    }, [gameChoice])

    const chooseGame = (game) => {
      setGameChoice(game)
    }

    if (!gameChoice) {
      return (
        <LocationProvider>
          <Router> 
            <Lobby path="/" teams={teams}/>
            <Admin path="/admin/loggedin" teams={teams}/>
            <Room path="/room/:name" chooseGame={chooseGame}/>
          </Router>
        </LocationProvider>
      )
    }

    return html`<${Game}/>`
  }

  render(html`<${App}></${App}`, document.getElementsByClassName('app')[0])
});
