import { Renderer } from "lance-gg";
import RoleInfoJSON from "../../dist/data/RoleInfo.json";
import PlayerObject from "../common/PlayerObject";
import TeamObject from "../common/TeamObject";
import PuzzGameObject from "../common/PuzzGameObject";
import TileObject from "../common/TileObject";
import { ImageLoader } from "./utils/ImageLoader";
import { TileMap } from "./utils/TileMap";

const IMAGE_LOADER = new ImageLoader();
const TILE_WIDTH = 16;
const TILE_HEIGHT = 16;

const CAMERA_RADIUS = 8;

const SPRITE_INFO = {
  // Geologist
  sprite_down_2: { x: 0, y: 0 },
  sprite_right_2: { x: 1, y: 0 },
  sprite_up_2: { x: 2, y: 0 },
  sprite_left_2: { x: 3, y: 0 },

  // Miner
  sprite_down_1: { x: 0, y: 1 },
  sprite_right_1: { x: 1, y: 1 },
  sprite_up_1: { x: 2, y: 1 },
  sprite_left_1: { x: 3, y: 1 },

  // Engineer
  sprite_down_0: { x: 0, y: 2 },
  sprite_right_0: { x: 1, y: 2 },
  sprite_up_0: { x: 2, y: 2 },
  sprite_left_0: { x: 3, y: 2 },
};

const NEW_TILES_INFO = {
  floor: { x: 0, y: 0 },

  // Boundary walls
  corner_top_left: { x: 0, y: 1 },
  corner_top_right: { x: 1, y: 1 },
  horizontal_wall: { x: 2, y: 1 },
  wall_top_single: { x: 3, y: 1 },
  wall_top: { x: 4, y: 1 },

  corner_bottom_left: { x: 0, y: 2 },
  corner_bottom_right: { x: 1, y: 2 },
  vertical_wall: { x: 2, y: 2 },
  wall_front_single: { x: 3, y: 2 },
  vertical_wall_left: { x: 4, y: 2 },
  vertical_wall_right: { x: 5, y: 2 },

  wall_front_left: { x: 0, y: 3 },
  wall_front: { x: 1, y: 3 },
  wall_front_right: { x: 2, y: 3 },

  // Doors
  door: { x: 3, y: 3 },
  door_down: { x: 4, y: 3 },
  side_door_down: { x: 5, y: 3 },
  side_door: { x: 6, y: 3 },
  wall_side_door: { x: 6, y: 2 },

  // Rocks
  magma: { x: 0, y: 4 },
  magma_cooled: { x: 1, y: 4 },
  soft_rock: { x: 2, y: 4 },
  hard_rock: { x: 3, y: 4 },
  mythril_ore_silver: { x: 4, y: 4 },
  mythril_ore_blue: { x: 5, y: 4 },
  mythril_ingot_silver: { x: 4, y: 5 },
  mythril_ingot_blue: { x: 5, y: 5 },
  boulder: { x: 6, y: 4 },
  pit: { x: 7, y: 4 },

  // Other
  pressure_plate: { x: 0, y: 5 },
  deposit_point: { x: 1, y: 5 },
  shovel: { x: 0, y: 6 },
  pickaxe: { x: 1, y: 6 },
  gloves: { x: 2, y: 6 },
  torch: { x: 3, y: 6 },
};

const BOUNDARY_TILES = [
  // Boundary walls
  [TileObject.CORNER_TOP_LEFT, "corner_top_left"],
  [TileObject.CORNER_TOP_RIGHT, "corner_top_right"],
  [TileObject.CORNER_BOTTOM_LEFT, "corner_bottom_left"],
  [TileObject.CORNER_BOTTOM_RIGHT, "corner_bottom_right"],
  [TileObject.WALL_TOP_SINGLE, "wall_top_single"],
  [TileObject.WALL_FRONT_SINGLE, "wall_front_single"],
  [TileObject.HORIZONTAL_WALL, "horizontal_wall"],
  [TileObject.VERTICAL_WALL, "vertical_wall"],
  [TileObject.VERTICAL_WALL_LEFT, "vertical_wall_left"],
  [TileObject.VERTICAL_WALL_RIGHT, "vertical_wall_right"],
  [TileObject.WALL_FRONT_LEFT, "wall_front_left"],
  [TileObject.WALL_FRONT, "wall_front"],
  [TileObject.WALL_FRONT_RIGHT, "wall_front_right"],
  [TileObject.WALL_TOP, "wall_top"],
];

const TILES = [
  // Doors
  [TileObject.DOOR_A, "door"],
  [TileObject.DOOR_DOWN_A, "door_down"],
  [TileObject.SIDE_DOOR_DOWN_A, "side_door_down"],
  [TileObject.SIDE_DOOR_A, "side_door"],
  [TileObject.WALL_SIDE_DOOR_A, "wall_side_door"],

  [TileObject.DOOR_B, "door"],
  [TileObject.DOOR_DOWN_B, "door_down"],
  [TileObject.SIDE_DOOR_DOWN_B, "side_door_down"],
  [TileObject.SIDE_DOOR_B, "side_door"],
  [TileObject.WALL_SIDE_DOOR_B, "wall_side_door"],

  // Surfaces
  [TileObject.MAGMA, "magma"],
  [TileObject.MAGMA_COOLED, "magma_cooled"],
  [TileObject.PRESSURE_PLATE_A, "pressure_plate"],
  [TileObject.PRESSURE_PLATE_B, "pressure_plate"],
  [TileObject.DEPOSIT_POINT, "deposit_point"],
  [TileObject.PIT, "pit"],

  // Mineable rocks
  [TileObject.ROCK_HARD, "hard_rock"],
  [TileObject.ROCK_SOFT, "soft_rock"],
  [TileObject.MYTHRIL_ORE_SILVER, "mythril_ore_silver"],
  [TileObject.MYTHRIL_ORE_BLUE, "mythril_ore_blue"],

  // Objects
  [TileObject.BOULDER, "boulder"],
  [TileObject.MYTHRIL_INGOT_SILVER, "mythril_ingot_silver"],
  [TileObject.MYTHRIL_INGOT_BLUE, "mythril_ingot_blue"],
  [TileObject.SHOVEL, "shovel"],
  [TileObject.PICKAXE, "pickaxe"],
  [TileObject.GLOVES, "gloves"],
  [TileObject.TORCH, "torch"],
];

export class PuzzRenderer extends Renderer {
  constructor(gameEngine, clientEngine) {
    super(gameEngine, clientEngine);
    this.isReady = false;
    this.playerId = null;
    this.lastTeamInfoUpdate = null;

    // inputs
    this.domButtonInteract = document.querySelector("#buttonInteract");
    this.domButtonInteractText = this.domButtonInteract.getElementsByClassName("text")[0];

    this.domButtonDeposit = document.querySelector("#buttonDeposit");
    this.domButtonDepositText = this.domButtonDeposit.getElementsByClassName("text")[0];

    this.playerButtons = document.querySelector("#playerButtons");
    this.domChangeView = document.querySelector("#changeView");

    // mythril count
    this.mythrilCount = document.querySelector("#mythril-count");
    this.mythrilCountText = this.mythrilCount.getElementsByClassName("text")[0];

    // outputs
    this.domBoard = document.querySelector("#board");
    this.domTeamInfo = document.querySelector("#teamInfo");
    this.domPlayerInfo = document.querySelector("#playerInfo");
    this.domLegend = document.querySelector("#legendPopup");
    this.domLegendButton = document.querySelector("#button-legend");

    // canvas
    const canvas = document.createElement("canvas");
    this.domBoard.appendChild(canvas);
    const ctx = canvas.getContext("2d");
    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.msImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;
    this.canvas = canvas;
    this.ctx = ctx;
    this.sumT = 0.0;

    // legend popup
    let legendContainer = document.createElement("div");
    legendContainer.className = "popupContent";
    this.domLegend.appendChild(legendContainer);
    // legend text
    let legendElem = document.createElement("div");
    legendElem.innerHTML = RoleInfoJSON["3"]["legend"];
    legendContainer.appendChild(legendElem);
    // legend canvas
    const legendCanvas = document.createElement("canvas");
    legendCanvas.width = 500;
    const legendCtx = legendCanvas.getContext("2d");
    legendCanvas.className = "legendCanvas";
    legendCtx.webkitImageSmoothingEnabled = false;
    legendCtx.mozImageSmoothingEnabled = false;
    legendCtx.msImageSmoothingEnabled = false;
    legendCtx.imageSmoothingEnabled = false;
    this.legendCtx = legendCtx;
    legendContainer.appendChild(legendCanvas);
    // popup close
    let popupClose = document.createElement("button");
    popupClose.innerHTML = "X";
    popupClose.className = "x-button";
    popupClose.addEventListener("click", () => {
      document.querySelector("#legendPopup").style.display = "none";
    });
    this.domLegend.appendChild(popupClose);

    // music
    let player = new Audio("/audio/Ratatouille.mp3");
    player.loop = true;
    player.autoplay = true;
    player.volume = 0.25;
    player.muted = true;
  }

  resizeCanvas() {
    const canvas = this.canvas;
    const bounds = this.domBoard.getBoundingClientRect();

    canvas.style.width = bounds.width;
    canvas.style.height = bounds.height;

    const ratio = bounds.width / window.innerWidth;
    canvas.width = (bounds.width * ratio);
    canvas.height = (bounds.height * ratio);
  }

  async init() {
    console.log("init");

    // load assets here
    const spritesSource = await IMAGE_LOADER.load("/images/sprites.png");
    const spritesMap = new TileMap(spritesSource, TILE_WIDTH, TILE_HEIGHT, SPRITE_INFO);
    this.spritesMap = spritesMap;

    const tileSource = await IMAGE_LOADER.load("/images/tile_map.png");
    const tileMap = new TileMap(tileSource, TILE_WIDTH, TILE_HEIGHT, NEW_TILES_INFO);
    this.tileMap = tileMap;

    this.createLegend();
  }

  playerAdded(playerId) {
    console.info("playerAdded");
    if (playerId) {
      this.playerId = playerId;
    }
    this.updateInfo();
  }

  playerRemoved(playerId) {
    console.info("playerRemoved");
    if (playerId == this.playerId) {
      this.playerId = null;
    }
    this.updateInfo();
  }

  teamAdded() {
    console.info("teamAdded");
    this.domTeamInfo.style.display = "block";
  }

  teamRemoved() {
    console.info("teamRemoved");
    this.domTeamInfo.style.display = "none";
  }

  getPlayer() {
    return this.gameEngine.world.queryObject({
      instanceType: PlayerObject,
      playerId: this.playerId,
    });
  }

  getAllPlayers() {
    const team = this.gameEngine.world.queryObject({
      instanceType: TeamObject,
    });
    const players = [];
    for (const playerId of team.playerIds) {
      const player = this.gameEngine.world.queryObject({
        instanceType: PlayerObject,
        playerId,
      });
      players.push(player);
    }
    return players;
  }

  hideSection(domElement) {
    domElement.style.visibility = "hidden";
  }
  showSection(domElement) {
    domElement.style.visibility = "visible";
  }

  updateMythrilCount() {
    const game = this.gameEngine.world.queryObject({
      instanceType: PuzzGameObject,
    });
    if (game) {
      this.mythrilCountText.innerHTML = `Mythril Remaining: ${game.mythrilRemaining}`;
      this.mythrilCount.style.setProperty("background-color", "white");
    }
  }

  updateInfo() {
    this.lastTeamInfoUpdate = Date.now();
    const teamObject = this.gameEngine.world.queryObject({
      instanceType: TeamObject,
    });
    if (!teamObject) {
      return;
    }

    const player = this.getPlayer();
    if (!player) {
      return;
    }

    const game = this.gameEngine.world.queryObject({
      instanceType: PuzzGameObject,
    });
    if (!game) {
      return;
    }

    console.log("renderer - " + player.toString());

    this.showSection(this.playerButtons);
    this.showSection(this.mythrilCount);

    this.domButtonInteractText.textContent = player.interactionPrompt;
    this.domButtonInteract.disabled = !player.interactionEnabled;

    this.domButtonDepositText.textContent = `Deposit Mythril (Held: ${player.mythrilHeld})`;
    this.domButtonDeposit.disabled = !(game.getTileFaced(player.row, player.column, player.directionFaced).is(TileObject.DEPOSIT_POINT));

    this.domLegendButton.style.display = "block";
    this.domTeamInfo.innerHTML = "";

    const teamNameElem = document.createElement("div");
    const playerName = RoleInfoJSON[player.role]["name"];
    let topText = `${playerName}`;
    teamNameElem.innerText = topText;

    const seperatorElem = document.createElement("div");
    // seperatorElem.innerText = "------------";
    teamNameElem.appendChild(seperatorElem);
    teamNameElem.classList.add("TeamName");
    this.domTeamInfo.appendChild(teamNameElem);

    let playerNameElem = document.createElement("div");
    let bottomText = teamObject.playerNames
      .filter((n) => n != player.name)
      .join(", ");
    playerNameElem.innerText = bottomText;
    playerNameElem.classList.add("PlayerName");
    this.domTeamInfo.appendChild(playerNameElem);
  }

  createLegend(scaleFactor = 2) {
    this.tileMap.draw(
      this.legendCtx,
      "MYTHRIL_INGOT_BLUE_blue",
      TILE_WIDTH,
      0 * TILE_HEIGHT * scaleFactor,
      scaleFactor
    );
    const fontSize = 15;
    this.legendCtx.font = `${fontSize}px Arial`;
    this.legendCtx.fillText("mythril - collect all to escape", 100, fontSize * 2);
  }

  drawSprite(name, cx, cy, py = 0) {
    this.spritesMap.draw(
      this.ctx,
      name,
      cx * TILE_WIDTH,
      cy * TILE_HEIGHT + py
    );
  }

  drawTile(name, cx, cy, py = 0) {
    this.tileMap.draw(
      this.ctx,
      name,
      cx * TILE_WIDTH,
      cy * TILE_HEIGHT + py
    );
  }

  drawBoard(t, dt) {
    const ctx = this.ctx;
    this.resizeCanvas();

    const game = this.gameEngine.world.queryObject({
      instanceType: PuzzGameObject,
    });

    if (!game) {
      ctx.restore();
      return;
    }

    const ROWS = game.rows;
    const COLUMNS = game.columns;

    ctx.save();
    ctx.translate(
      Math.floor((ctx.canvas.width - (COLUMNS * TILE_WIDTH)) / 2),
      Math.floor((ctx.canvas.height - (ROWS * TILE_HEIGHT)) / 2)
    );

    const currentPlayer = this.getPlayer();

    for (
      let row = Math.max(0, currentPlayer.row - CAMERA_RADIUS);
      row <= Math.min(currentPlayer.row + CAMERA_RADIUS, ROWS - 1);
      ++row
    ) {

      for (
        let column = Math.max(0, currentPlayer.column - CAMERA_RADIUS);
        column <= Math.min(currentPlayer.column + CAMERA_RADIUS, COLUMNS - 1);
        ++column
      ) {

        // Skip rendering if outside vision circle
        if ((
          Math.abs(currentPlayer.row - row) ** 2 + Math.abs(currentPlayer.column - column) ** 2)
          >= (CAMERA_RADIUS ** 2)
        ) {
          continue;
        }

        // Draw floor
        const tile = game.getTile(row, column);
        this.drawTile("floor", column, row);

        // Draw boundaries and additional tiles
        for (let i = 0; i < BOUNDARY_TILES.length; i++) {
          if (tile.is(BOUNDARY_TILES[i][0])) {
            this.drawTile(BOUNDARY_TILES[i][1], column, row);
            break;
          }
        }

        for (let i = 0; i < TILES.length; i++) {
          if (tile.hasAny(TILES[i][0])) {
            this.drawTile(TILES[i][1], column, row);
            // Don't break here, these tiles can overlap
          }
        }

        // Draw players
        for (const player of this.getAllPlayers()) {
          if (!(
            (Math.abs(currentPlayer.row - player.row) ** 2 + Math.abs(currentPlayer.column - player.column) ** 2)
            >= (CAMERA_RADIUS ** 2)
          )) {
            const roleId = Math.min(2, player.role);
            switch (player.directionFaced) {
              case PlayerObject.DIRECTION_UP:
                this.drawSprite(`sprite_up_${roleId}`, player.column, player.row);
                break;
              case PlayerObject.DIRECTION_DOWN:
                this.drawSprite(`sprite_down_${roleId}`, player.column, player.row);
                break;
              case PlayerObject.DIRECTION_LEFT:
                this.drawSprite(`sprite_left_${roleId}`, player.column, player.row);
                break;
              case PlayerObject.DIRECTION_RIGHT:
                this.drawSprite(`sprite_right_${roleId}`, player.column, player.row);
                break;
            }
          }
        }
      }
    }
    ctx.restore();
  }

  draw(t, dt) {
    this.sumT += dt;
    // target 10ish fps, try to use less CPU
    if (this.sumT < 80.0) {
      return;
    }
    this.sumT = 0.0;
    super.draw(t, dt);

    const now = Date.now();
    if (!this.lastTeamInfoUpdate || now - this.lastTeamInfoUpdate > 500) {
      this.updateInfo();
    }
    this.drawBoard(t, dt);
    this.updateMythrilCount();
  }
}
