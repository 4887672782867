import { ClientEngine, KeyboardControls } from "lance-gg";
import { PuzzRenderer } from "./PuzzRenderer";
import PuzzGameObject from "../common/PuzzGameObject";
import Cookies from "js-cookie";
import PlayerObject from "../common/PlayerObject";

export class PuzzClientEngine extends ClientEngine {
  constructor(gameEngine, options) {
    super(gameEngine, options, PuzzRenderer);
    this.ended = false;
    this.clientUUID = "";
    this.teamName = options.teamName

    this.controls = new KeyboardControls(this.renderer.clientEngine);
    this.controls.bindKey("up", "moveUp", { repeat: false });
    this.controls.bindKey("left", "moveLeft", { repeat: false });
    this.controls.bindKey("down", "moveDown", { repeat: false });
    this.controls.bindKey("right", "moveRight", { repeat: false });

    this.controls.bindKey("w", "moveUp", { repeat: false });
    this.controls.bindKey("a", "moveLeft", { repeat: false });
    this.controls.bindKey("s", "moveDown", { repeat: false });
    this.controls.bindKey("d", "moveRight", { repeat: false });

    this.controls.bindKey("f", "interact", { repeat: false });
    this.controls.bindKey("g", "deposit", { repeat: false });
    this.controls.bindKey("escape", "reset", { repeat: false });

    document.querySelector("#buttonInteract").addEventListener("click", () => {
      this.sendInput("interact");
    });
    document.querySelector("#buttonDeposit").addEventListener("click", () => {
      this.sendInput("deposit");
    });
    document.querySelector("#button-legend").addEventListener("click", () => {
      document.querySelector("#legendPopup").style.display = "block";
    });
  }

  getPlayer() {
    return this.gameEngine.world.queryObject({
      playerId: this.gameEngine.playerId,
      instanceType: PlayerObject,
    });
  }

  start() {
    super.start();
  }

  // extend ClientEngine connect to add own events
  async connect() {
    const socketQuery = {};
    if (window.location.search.length > 1) {
      const query = window.location.search.substring(1);
      const params = query.split("&");
      params.forEach((param) => {
        const pair = param.split("=");
        socketQuery[pair[0]] = pair[1];
      });
    }

    socketQuery.teamid = this.teamName

    let forceUuid = false;
    if (socketQuery.ClientUUID) {
      forceUuid = true;
    } else {
      this.clientUUID = Cookies.get("ClientUUID")
        ? Cookies.get("ClientUUID")
        : "";
      socketQuery.ClientUUID = this.clientUUID;
    }

    console.log(socketQuery);
    let socketOptions = {
      query: socketQuery,
    };

    return super.connect(socketOptions).then(() => {
      window.onunload = function () {
        this.socket.disconnect();
      };

      this.socket.on("disconnect", (e) => {
        console.log("disconnected");
        this.socket.disconnect();
        if (!this.ended) {
          let scoreText = document.querySelector("#scoreText");
          scoreText.innerText =
            "Disconnected from server unexpectedly. You can refresh to reconnect.";

          let gameScreen = document.querySelector("#gameScreen");
          gameScreen.style.display = "none";

          let scoreScreen = document.querySelector("#scoreScreen");
          scoreScreen.style.display = "initial";
        }
      });

      this.socket.on(
        "user-uuid",
        function (clientUUID) {
          if (!forceUuid && this.clientUUID !== clientUUID) {
            this.clientUUID = clientUUID;
            Cookies.set("ClientUUID", this.clientUUID);
          }
        }.bind(this)
      );

      this.socket.on(
        "reset-level",
        function(){
          this.socket.emit("reset-level")
        }.bind(this)
      )

      this.socket.on(
        "reset-to-beginning",
        function(){
          this.socket.emit("reset-to-beginning")
        }.bind(this)
      )

      this.socket.on(
        "end-game",
        function (message) {
          this.ended = true;

          let scoreText = document.querySelector("#scoreText");
          scoreText.innerText = message;

          let gameScreen = document.querySelector("#gameScreen");
          gameScreen.style.display = "none";

          let scoreScreen = document.querySelector("#scoreScreen");
          scoreScreen.style.display = "initial";
        }.bind(this)
      );
    });
  }
}
