export class ImageLoader {
  async load(url) {
    const image = new Image();
    return new Promise((res, rej) => {
      image.onload = () => {
        res(image);
      };
      image.onerror = (event) => {
        rej(new Error(`Could not load image "${url}"`));
      };
      image.src = url;
    });
  }
}
