export class TileMap {
  constructor(source, tileWidth, tileHeight, tileInfo, scaleFactor = 1) {
    this.source = source;
    this.tileWidth = tileWidth;
    this.tileHeight = tileHeight;
    this.tileInfo = tileInfo;
    this.scaleFactor = scaleFactor;
  }
  draw(ctx, name, x, y, scale = 1) {
    const tile = this.tileInfo[name];
    if (tile) {
      ctx.drawImage(
        this.source,
        tile.x * this.tileWidth,
        tile.y * this.tileHeight,
        this.tileWidth,
        this.tileHeight,
        x,
        y,
        this.tileWidth * scale,
        this.tileHeight * scale
      );
    }
  }
}
