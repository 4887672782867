import { BaseTypes, DynamicObject } from "lance-gg";
import PlayerObject from "./PlayerObject";
import TileObject, { TileCharacters } from "./TileObject";
import { LEVELS, TEST_LEVELS } from "./level-data/Levels";

export default class PuzzGameObject extends DynamicObject {

  static get netScheme() {
    return Object.assign(
      {
        // Top left Tile is Grid (0, 0), (row, column)
        rows: { type: BaseTypes.TYPES.INT8 },
        columns: { type: BaseTypes.TYPES.INT8 },
        tiles: {
          type: BaseTypes.TYPES.LIST,
          itemType: BaseTypes.TYPES.CLASSINSTANCE,
        },
        level: { type: BaseTypes.TYPES.INT8 },
        mythrilRemaining: { type: BaseTypes.TYPES.INT8 },
      },
      super.netScheme
    );
  }

  constructor(gameEngine, options, props) {
    props = props || {};
    props.isStatic = true;
    super(gameEngine, options, props);

    this.rows = props.rows ?? 0;
    this.columns = props.columns ?? 0;
    this.tiles = props.tiles ?? [];
    this.level = props.level ?? 0;
    this.levels = LEVELS;

    // Level specific
    this.playerSpawnPoints = new Array(4);
    this.doorLocations = [[], []];
    this.doorsOpen = [false, false];
    this.mythrilRemaining = 0;

    // Accessed externally
    this.players = [];
    this.done = false;
  }

  syncTo(other) {
    super.syncTo(other);
    this.rows = other.rows;
    this.columns = other.columns;
    this.tiles = other.tiles;
    this.level = other.level;
    this.mythrilRemaining = other.mythrilRemaining;
  }

  toString() {
    return `GameObject[roomName=${this._roomName} id=${this.id} rows=${this.rows} columns=${this.columns} tiles=${this.tiles.length} numPlayers=${this.players.length}]`;
  }

  // Tile access
  getTile(row, column) {
    if (row < 0 || column < 0 || row >= this.rows || column >= this.columns) {
      return null;
    };
    const index = (row * this.columns) + column;
    return this.tiles[index];
  }

  getTileFaced(row, column, directionFaced = null) {
    let rowDiff = 0;
    let columnDiff = 0;
    switch (directionFaced) {
      case PlayerObject.DIRECTION_UP:
        rowDiff = -1;
        break;
      case PlayerObject.DIRECTION_DOWN:
        rowDiff = 1;
        break;
      case PlayerObject.DIRECTION_LEFT:
        columnDiff = -1;
        break;
      case PlayerObject.DIRECTION_RIGHT:
        columnDiff = 1;
        break;
      default:
        return this.getTile(row, column);
    }
    return this.getTile(row + rowDiff, column + columnDiff);
  }

  // Level setup
  setLevel(level = this.level) {
    this.doorLocations = [[], []];
    this.doorsOpen = [false, false];

    const levelLayout = this.levels[level];
    for (let [row, layoutString] of levelLayout.entries()) {
      for (let column = 0; column < layoutString.length; ++column) {
        const character = layoutString[column];

        // Set tile according to map
        const tile = this.getTile(row, column);
        const tileBits = TileCharacters[character] || 0;
        tile.set(tileBits);

        // Count mythril in level
        if (tileBits === TileObject.MYTHRIL_ORE_BLUE || tileBits === TileObject.MYTHRIL_INGOT_BLUE) {
          this.mythrilRemaining++;
        }
        else if (tileBits === TileObject.MYTHRIL_ORE_SILVER || tileBits === TileObject.MYTHRIL_INGOT_SILVER) {
          this.mythrilRemaining++;
        }
        // Track door locations
        else if (tileBits === TileObject.DOOR_A || tileBits === TileObject.SIDE_DOOR_A) {
          this.doorLocations[0].push({ row, column, tileBits });
        }
        else if (tileBits === TileObject.DOOR_B || tileBits === TileObject.SIDE_DOOR_B) {
          this.doorLocations[1].push({ row, column, tileBits });
        }
        // Track player spawn points
        else if (parseInt(character) < this.playerSpawnPoints.length) {
          this.playerSpawnPoints[parseInt(character)] = { row, column };
        }
      }
    }
  }

  incrementLevel() {
    const level = ++this.level;
    if (level < this.levels.length) {
      this.setLevel(level);
      for (const player of this.players) {
        this.resetPlayer(player);
      }
    } else {
      this.done = true;
    }
  }

  resetPlayer(player) {
    if (!player) {
      return;
    }

    // Remove player from previous tile
    this.getTile(player.row, player.column).remove(TileObject.PLAYER);

    // Revert to spawn point
    const spawnPoint = this.playerSpawnPoints[player.role] ?? this.playerSpawnPoints[0];
    this.getTile(spawnPoint.row, spawnPoint.column).add(TileObject.PLAYER);

    // Reset player
    player.reset(spawnPoint);
  }

  // Inputs
  processButton(button, player) {
    switch (button) {
      case PlayerObject.BUTTON_UP:
      case PlayerObject.BUTTON_DOWN:
      case PlayerObject.BUTTON_LEFT:
      case PlayerObject.BUTTON_RIGHT:
        this.handleMovement(button, player);
        break;
      case PlayerObject.BUTTON_INTERACT:
        this.handleInteraction(player);
        break;
      case PlayerObject.BUTTON_DEPOSIT:
        this.handleDeposit(player);
        break;
      case PlayerObject.BUTTON_RESET:
        this.resetPlayer(player);
        break;
    }
    this.updateDoorToggle();
  }

  updateDoorToggle() {
    // Determine whether any pressure plates are held
    let pressurePlatesHeld = [false, false];
    for (const tile of this.tiles) {
      if (tile.hasAny(TileObject.PLAYER) || tile.hasAny(TileObject.BOULDER)) {
        if (tile.hasAny(TileObject.PRESSURE_PLATE_A)) {
          pressurePlatesHeld[0] = true;
        } else if (tile.hasAny(TileObject.PRESSURE_PLATE_B)) {
          pressurePlatesHeld[1] = true;
        }
      }
    }

    // Toggle doors accordingly
    for (const location of this.doorLocations[0]) {
      const doorTile = this.getTile(location.row, location.column)
      // The side facing door spans two tiles, so fetch the tile above
      // in case we need to update it
      const tileAbove = this.getTile(location.row - 1, location.column)
      this.doorsOpen[0] = pressurePlatesHeld[0];

      if (this.doorsOpen[0]) {
        if (location.tileBits === TileObject.DOOR_A) {
          doorTile.remove(TileObject.DOOR_A);
          doorTile.add(TileObject.DOOR_DOWN_A);
        } else if (location.tileBits === TileObject.SIDE_DOOR_A) {
          doorTile.set(TileObject.SIDE_DOOR_DOWN_A);
          tileAbove.set(TileObject.WALL_FRONT);
        }
      } else {
        doorTile.set(location.tileBits);
        if (location.tileBits === TileObject.SIDE_DOOR_A) {
          tileAbove.set(TileObject.WALL_SIDE_DOOR_A);
        }
      }
    }

    // Toggle doors accordingly
    for (const location of this.doorLocations[1]) {
      const doorTile = this.getTile(location.row, location.column)
      // The side facing door spans two tiles, so fetch the tile above
      // in case we need to update it
      const tileAbove = this.getTile(location.row - 1, location.column)
      this.doorsOpen[1] = pressurePlatesHeld[1];

      if (this.doorsOpen[1]) {
        if (location.tileBits === TileObject.DOOR_B) {
          doorTile.remove(TileObject.DOOR_B);
          doorTile.add(TileObject.DOOR_DOWN_B);
        } else if (location.tileBits === TileObject.SIDE_DOOR_B) {
          doorTile.set(TileObject.SIDE_DOOR_DOWN_B);
          tileAbove.set(TileObject.WALL_FRONT);
        }
      } else {
        doorTile.set(location.tileBits);
        if (location.tileBits === TileObject.SIDE_DOOR_B) {
          tileAbove.set(TileObject.WALL_SIDE_DOOR_B);
        }
      }
    }
  }

  handleMovement(button, player) {
    // Determine next tile
    let rowDiff = 0;
    let columnDiff = 0;
    switch (button) {
      case PlayerObject.BUTTON_UP:
        player.directionFaced = PlayerObject.DIRECTION_UP;
        rowDiff = -1;
        break;
      case PlayerObject.BUTTON_DOWN:
        player.directionFaced = PlayerObject.DIRECTION_DOWN;
        rowDiff = 1;
        break;
      case PlayerObject.BUTTON_LEFT:
        player.directionFaced = PlayerObject.DIRECTION_LEFT;
        columnDiff = -1;
        break;
      case PlayerObject.BUTTON_RIGHT:
        player.directionFaced = PlayerObject.DIRECTION_RIGHT;
        columnDiff = 1;
        break;
    }

    const newTile = this.getTileFaced(player.row, player.column, player.directionFaced);
    const nextTile = this.getTileFaced(player.row + rowDiff, player.column + columnDiff, player.directionFaced);
    if (!(newTile)) {
      return;
    }

    // Push boulder
    if (newTile.hasAny(TileObject.BOULDER) && nextTile) {
      this.handleBoulderPush(newTile, nextTile);
    }

    // Move player
    if (newTile.isWalkable()) {

      this.getTile(player.row, player.column).remove(TileObject.PLAYER);
      newTile.add(TileObject.PLAYER);
      player.row += rowDiff;
      player.column += columnDiff;

      // Collect mythril
      for (const ingot of [TileObject.MYTHRIL_INGOT_BLUE, TileObject.MYTHRIL_INGOT_SILVER]) {
        if (newTile.hasAny(ingot)) {
          newTile.remove(ingot);
          player.mythrilHeld++;
        }
      }
    }
  }

  handleBoulderPush(newTile, nextTile) {
    if (nextTile.isWalkable()) {
      newTile.remove(TileObject.BOULDER);
      nextTile.add(TileObject.BOULDER);
      return;
    }

    switch (nextTile.bits) {
      case TileObject.MAGMA:
        newTile.remove(TileObject.BOULDER);
        nextTile.set(TileObject.MAGMA_COOLED);
        break;
      case TileObject.PIT:
        newTile.remove(TileObject.BOULDER);
        break;
    }
  }

  // Interaction button
  handleInteraction(player) {
    if (!player.interactionEnabled) {
      return;
    }
    switch (player.role) {
      case PlayerObject.ROLE_GEOLOGIST:
        this.handleGeologistInteraction(player);
        break;
      case PlayerObject.ROLE_MINER:
        this.handleMinerInteraction(player);
        break;
      case PlayerObject.ROLE_ENGINEER:
        this.handleEngineerInteraction(player);
        break;
      case PlayerObject.ROLE_ALL:
        this.handleGeologistInteraction(player);
        this.handleMinerInteraction(player);
        this.handleEngineerInteraction(player);
    }
  }

  handleGeologistInteraction(player) {
    const tileFaced = this.getTileFaced(player.row, player.column, player.directionFaced);
    tileFaced.remove(TileObject.ROCK_SOFT);
    if (tileFaced.hasAny(TileObject.MYTHRIL_ORE_SILVER)) {
      tileFaced.remove(TileObject.MYTHRIL_ORE_SILVER)
      tileFaced.add(TileObject.MYTHRIL_INGOT_SILVER);
    }
  }

  handleMinerInteraction(player) {
    const tileFaced = this.getTileFaced(player.row, player.column, player.directionFaced);
    tileFaced.remove(TileObject.ROCK_HARD);
    if (tileFaced.hasAny(TileObject.MYTHRIL_ORE_BLUE)) {
      tileFaced.remove(TileObject.MYTHRIL_ORE_BLUE)
      tileFaced.add(TileObject.MYTHRIL_INGOT_BLUE);
    }
  }

  handleEngineerInteraction(player) {
    const tileFaced = this.getTileFaced(player.row, player.column, player.directionFaced);
    if (tileFaced.hasAny(TileObject.BOULDER) && !player.hasBoulder) {
      tileFaced.remove(TileObject.BOULDER);
      player.hasBoulder = true;
    } else if (tileFaced.isWalkable() && player.hasBoulder) {
      tileFaced.add(TileObject.BOULDER);
      player.hasBoulder = false;
    }
  }

  updateInteractionEnablement() {
    for (const player of this.players) {
      if (!player) {  // Happens as players are connecting
        continue;
      }
      const tileFaced = this.getTileFaced(player.row, player.column, player.directionFaced);
      switch (player.role) {
        case PlayerObject.ROLE_GEOLOGIST:
          player.interactionEnabled = tileFaced.hasAny(TileObject.ROCK_SOFT) || tileFaced.hasAny(TileObject.MYTHRIL_ORE_SILVER);
          break;
        case PlayerObject.ROLE_MINER:
          player.interactionEnabled = tileFaced.hasAny(TileObject.ROCK_HARD) || tileFaced.hasAny(TileObject.MYTHRIL_ORE_BLUE);
          break;
        case PlayerObject.ROLE_ENGINEER:
          player.interactionEnabled = player.hasBoulder ? tileFaced.isWalkable() : tileFaced.hasAny(TileObject.BOULDER);
          break;
        default:
          player.interactionEnabled = true;
      }
    }
  }

  handleDeposit(player) {
    if (!player.mythrilHeld) {
      return;
    }
    const tileFaced = this.getTileFaced(player.row, player.column, player.directionFaced);
    if (tileFaced.is(TileObject.DEPOSIT_POINT)) {
      player.mythrilHeld--;
      this.mythrilRemaining--;
      if (this.mythrilRemaining == 0) {
        this.incrementLevel();
      }
    }
  }
}
