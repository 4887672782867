import { SimplePhysicsEngine, GameEngine } from "lance-gg";
import PlayerObject from "./PlayerObject";
import PuzzGameObject from "./PuzzGameObject";
import TeamObject from "./TeamObject";
import TileObject from "./TileObject";

export const GAME_ROWS = 25;
export const GAME_COLUMNS = 25;

const INPUT_MAPPINGS = new Map([
  ["moveUp", PlayerObject.BUTTON_UP],
  ["moveDown", PlayerObject.BUTTON_DOWN],
  ["moveLeft", PlayerObject.BUTTON_LEFT],
  ["moveRight", PlayerObject.BUTTON_RIGHT],
  ["interact", PlayerObject.BUTTON_INTERACT],
  ["deposit", PlayerObject.BUTTON_DEPOSIT],
  ["reset", PlayerObject.BUTTON_RESET],
]);

export default class PuzzGameEngine extends GameEngine {
  constructor(options) {
    super(options);
    this.physicsEngine = new SimplePhysicsEngine({
      gameEngine: this,
      collisions: {
        type: "brute",
        collisionDistance: 28,
      },
    });
  }

  // Register the classes of objects that will get sent to the client from the server
  registerClasses(serializer) {
    serializer.registerClass(PlayerObject);
    serializer.registerClass(PuzzGameObject);
    serializer.registerClass(TeamObject);
    serializer.registerClass(TileObject);
  }

  // Start the world
  start() {
    super.start();
  }

  step() {
  }

  // Handle Game Inputs
  processInput(inputData, playerId, isServer) {
    if (!isServer) {
      return;
    }

    const player = this.world.queryObject({ playerId, instanceType: PlayerObject });
    if (!player) {
      console.warn(`Skipping input, unregistered player (id=${playerId})`);
      return;
    }

    const button = INPUT_MAPPINGS.get(inputData.input);
    if (button === undefined) {
      console.warn(`Skipping unrecognised input ${inputData.input}`);
      return;
    }

    super.processInput(inputData, playerId);

    const game = player.game;
    game.processButton(button, player);
    game.updateInteractionEnablement();
  }

  makeGame(roomName) {
    const tiles = [];
    const rows = GAME_ROWS;
    const columns = GAME_COLUMNS;

    for (let row = 0; row < rows; ++row) {
      for (let column = 0; column < columns; ++column) {
        const tileOptions = {};
        const tileProps = { isStatic: 1 }
        const tile = new TileObject(this, tileOptions, tileProps);
        tiles.push(tile);
      }
    }

    console.log(tiles);

    const options = {};
    const props = { rows, columns, tiles };

    const game = new PuzzGameObject(this, options, props);
    game._roomName = roomName;

    this.addObjectToWorld(game);
    console.log(`Game added: ${game.toString()}`);
    return game;
  }

  makeTeam(roomName, teamName) {
    const options = {};
    const props = { teamName }

    const team = new TeamObject(this, options, props);
    team._roomName = roomName;

    this.addObjectToWorld(team);
    console.log(`Team added: ${team.toString()}`);
    return team;
  }

  makePlayer(roomName, props) {
    const options = {};
    const player = new PlayerObject(this, options, props);
    player._roomName = roomName;

    this.addObjectToWorld(player);
    console.log(`Player added: ${player.toString()}`);
    return player;
  }
}
