export const LEVELS = [
    [
        "<####>=============<####>",
        "<####>             <####>",
        "<####>  0   2   1  <####>",
        "<####>             <####>",
        "<#####-╗S╔-╗ ╔-╗H╔-#####>",
        "<######>S<>}B{<>H<######>",
        "<######>?<>   <>!<######>",
        "<######>S<> O <>H<######>",
        "<####>=}S{} n {}H{=<####>",
        "<####>      u      <####>",
        "<####>  @   @   @  <####>",
        "<####>             <####>",
        "<####>    ╔╗B╔╗    <####>",
        "<####>    |} {|    <####>",
        "<#####╗H╔->   <-╗S╔#####>",
        "<#####>H<#> n <#>S<#####>",
        "<#####>H<#> £ <#>S<#####>",
        "<#####>H<#> u <#>S<#####>",
        "<#####>H<#>   <#>S<#####>",
        "<#####>H<##╗ ╔##>S<#####>",
        "<#####>H<>=}B{=<>S<#####>",
        "<#####>H{}     {}S<#####>",
        "<#####>HHHBnSnBSSS<#####>",
        "<######--╗ |!| ╔--######>",
        "<#########-#-#-#########>",
    ],
    [
        "╔-----------------------╗",
        "<#>=====<>=====<>=====<#>",
        "<#> BB B<>OB  B<>BOOOB<#>",
        "<#>BB0B <>B 1BB<>OB2BO<#>",
        "<#> BO B<>B BB!<>BBBOB<#>",
        "<#>B BBO<> BBO <>BOB B<#>",
        "<#>  B B<>BOB  <>BBBBB<#>",
        "<#> BO? <>  BOB<>BOBBB<#>",
        "<#> O B <>O  B <>B$BOB<#>",
        "<#> B  O<#-╗ ╔-#>BBBBB<#>",
        "<##-╗ ╔-###> <###-╗ ╔-##>",
        "|===}B{====}B{====}B{===|",
        "|MMMMMMMMMMMMMMMMMMMMMMM|",
        "|MMMn ╔----╗ ╔--╗Mn nMMM|",
        "<╗M╔> <#>==} {==|M| |MMM|",
        "<>M<> <#>       |M| <--->",
        "<>M<> {=}  MMM  |M| {=<#>",
        "<>M<>      M!M  uMu   <#>",
        "<>M<> ╔-╗  MMM  BM  @ <#>",
        "<>M<> <#>       nMn  B<#>",
        "<>M<} {<#--╗ ╔--╝M╚---##>",
        "<>MuMMM{===}S<#>}M{==<##>",
        "<>MMM?MBSSSSS<#>MMMMM<##>",
        "<#-╗MMM╔-----##>MMMMM<##>",
        "<###---########>MMMMM<##>",
    ],
    [
        "╔-----------------------╗",
        "<######>===<############>",
        "<######>   <############>",
        "<####>=} @ {=<##########>",
        "<####>       {=======<##>",
        "<####> p   P MB      <##>",
        "|==<#>       ╔----╗SS<##>",
        "|? {=}       {====}SS{=<>",
        "<╗ DdBDDDDDDDHHSSB Pd P<>",
        "|} dDBdddddddSSHHBD pDp<>",
        "|  ╔-╗       ╔----╗dD╔-#>",
        "<--##> P   p <####>Dd<##>",
        "<####>       <##>=}dD{=<>",
        "<#####--╗ ╔--###>      <>",
        "<#####>=}H{=<###>      <>",
        "<#####>SSSSS<###> BBBB <>",
        "<#####>SnBnS<###> B?!B <>",
        "<#####>S|£|S<####-╗  ╔-#>",
        "<>===<> uHu <>===<>@@<##>",
        "<> 0 <>  2  <> 1 <#--###>",
        "|}   {}     {}   {<#####>",
        "|! P DD  P  DD P ?<#####>",
        "<╗   ╔╗     ╔╗   ╔######>",
        "<#---##-----##---#######>",
        "{=======================}",
    ],
    [
        "╔-----------------------╗",
        "<>===|=====<############>",
        "<> 2 | 0 1 <############>",
        "<>  D|  p  <##>=====<###>",
        "<> ╔-#╗   ╔#>=|MMMMM|=<#>",
        "<> {==|DDD<>}?uM   Mu!{<>",
        "<>   P|   <>SdBM $ MBdH<>",
        "<#-╗ <>   <>S BM   MB H<>",
        "<#=} <>ddd<>SBBMMdMMBBH<>",
        "<>ddd<}   {|SBBM   MBBH<>",
        "<>B╔->pD   |SpBM p MBpH<>",
        "<> {=>DD   |S  M   M  H<>",
        "<>DDD|     |S  MMdMM  H<>",
        "<#-╗B|ddDdd<-╗ M   M ╔-#>",
        "<>=} |SnBnH<#> M @ M <##>",
        "<>ddd|S|MuH{=} M   M <##>",
        "<> ╔->SuMBHHHHdMMMMMd<##>",
        "<> {=|SBM╔-╗Hn MMMMM <##>",
        "<>   <-╗P<#>H|       <##>",
        "<#-╗ {=}P<#>H<-╗ p ╔-###>",
        "<##>    M{<>H<#>   <####>",
        "<###--╗MMM{}B{=}   <####>",
        "<#####>M?  BMM     <####>",
        "<#####>MMM╔--------#####>",
        "{=======================}",
    ],
];


// Testing
export const TEST_LEVELS = [
    [
        "|||||||||||||||||||||||||",
        "|0123                   |",
        "|        HHHHHHH        |",
        "|                       |",
        "|        SSSSSSS        |",
        "|                       |",
        "|        OOOOOOO        |",
        "|                       |",
        "|        BBBBBBB        |",
        "|                       |",
        "|        PPPPPPP        |",
        "|        ppppppp        |",
        "|        *******        |",
        "|        DDDDDDD        |",
        "|        ddddddd        |",
        "|                       |",
        "|        MMMMMMM        |",
        "|                       |",
        "|        ?!?!?!?        |",
        "|                       |",
        "|        $£$£$£$        |",
        "|                       |",
        "|        @@@@@@@        |",
        "|                       |",
        "|||||||||||||||||||||||||",
    ],
    [
        "|||||||||||||||||||||||||",
        "|||||||||||||||||||||||||",
        "||| 0 ||  2  || 1 |||||||",
        "||| P DD  P  DD P |||||||",
        "|||   || |H| ||   |||||||",
        "||||||||S|!|S||||||||||||",
        "||HHHH||S|H|S||| MMB   B|",
        "||HS*S||SSSSS|||BMMSMS  |",
        "||HSSS||||H|||||  MM*M  |",
        "||H SS||||B||||| SMBMH  |",
        "||H S||||| ||||| MBMSM  |",
        "||| |||       || SHMSS B|",
        "||| |||  MMM  ||  S H  M|",
        "||   B        || S    BM|",
        "|| ||||       |||||  ||||",
        "|| |||||S|B|H||||||  ||||",
        "|| |||||S|M|H||||      ||",
        "|HHHH|||S|MBHHHHH @  @ ||",
        "|HSSSSSSSBM|||H||      ||",
        "|HHHH||||| ||| ||||  ||||",
        "|H*HH||||MMM||B||||  ||||",
        "|||||||||M!  BMM     ||||",
        "|||||||||MMM|||||||||||||",
        "|||||||||||||||||||||||||",
        "|||||||||||||||||||||||||",
    ],
]
